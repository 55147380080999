import React from 'react';
import { Box } from '@chakra-ui/react';

import Footer from './components/footer';
import Header from './components/header';

export interface LayoutProps {
  headerColorScheme: 'dark' | 'light';
}

const Layout: React.FC<LayoutProps> = ({
  children,
  headerColorScheme,
  ...props
}) => (
  <>
    <Header colorScheme={headerColorScheme} {...props} />
    <Box as="main">{children}</Box>
    <Footer {...props} />
  </>
);

export default Layout;
