import { useCallback } from 'react';

export interface MessageBirdChatWidget {
  startConversation: (message: string) => void;
}

const useMessageBird = () =>
  useCallback(() => {
    if (typeof window !== 'undefined')
      return (
        window as typeof window & {
          MessageBirdChatWidget: MessageBirdChatWidget;
        }
      ).MessageBirdChatWidget;

    return {} as MessageBirdChatWidget;
  }, [typeof window]);

export default useMessageBird;
