/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import {
  Flex,
  Link,
  List,
  Button,
  HStack,
  ListItem,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
  Fade,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { StaticImage } from 'gatsby-plugin-image';
import Icon from 'feather-icons-react';
import { useAtom } from 'jotai';
import { CircleFlag } from 'react-circle-flags';

import { useMessageBird } from '../hooks';
import * as atoms from '../atoms';

export interface MenuInterface {
  name: string;
  to?: string;
  children?: Array<MenuInterfaceChild>;
}

export type MenuInterfaceChild = Record<'title' | 'icon' | 'to', string>;

export interface HeaderProps {
  colorScheme?: 'dark' | 'light';
}

const menu: Array<MenuInterface> = [
  { name: 'About', to: '/about' },
  {
    name: 'Services',
    children: [
      {
        title: 'Currencies',
        to: '/currencies',
        icon: 'dollar-sign',
      },
      {
        title: 'Shop',
        to: '/shop',
        icon: 'shopping-cart',
      },
      {
        title: 'Shipping',
        to: '/shipping',
        icon: 'truck',
      },
    ],
  },
  { name: 'Contact', to: '/contact' },
];

const ListItemWithDropdown: React.FC<MenuInterface & { isActive: boolean }> = ({
  name,
  children,
  isActive,
}) => (
  <>
    <MenuButton
      p={0}
      mt={0}
      as={Button}
      height="auto"
      variant="transparent"
      rightIcon={!isActive ? <ChevronDownIcon /> : <ChevronUpIcon />}
    >
      {name}
    </MenuButton>
    <MenuList as="ul" bg="white" mt={2} ml={-2} p={3} px={0}>
      {children?.map((item) => (
        <MenuItem p={0} as="li" color="purple" key={item.title}>
          <Box
            py={2}
            px={5}
            w="100%"
            to={item.to}
            display="flex"
            as={GatsbyLink}
            cursor="pointer"
            fontWeight={300}
            sx={{ svg: { mr: 3 } }}
          >
            <Icon icon={item.icon} width="16px" />
            <Text fontSize="14px">{item.title}</Text>
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  </>
);

const Header: React.FC<HeaderProps> = ({ colorScheme = 'light' }) => {
  const mobileMenu = useDisclosure();
  const messageBird = useMessageBird();
  const isSmallScreen = useBreakpointValue({
    lg: false,
    md: false,
    base: true,
  });
  const isDark = useMemo(() => colorScheme === 'dark', [colorScheme]);
  const [currency, setCurrency] = useAtom(atoms.currency);

  const handleTradeNowButtonClick = useCallback(
    () => messageBird()?.startConversation('Hello, I want to trade.'),
    [messageBird],
  );

  const showCurrencyChanger =
    typeof window !== 'undefined' &&
    /^\/(shop|products)/.test(window.location.pathname);

  return (
    <Flex
      top={0}
      left={0}
      as="header"
      wrap="wrap"
      width="100vw"
      height="80px"
      align="center"
      zIndex="docked"
      position="sticky"
      padding="0 var(--cp)"
      justify="space-between"
      bg={isDark ? 'purple' : '#F4F4F4'}
    >
      <Flex align="center" as={GatsbyLink} to="/">
        {isSmallScreen ? (
          <StaticImage
            width={60}
            quality={100}
            alt="Jamestown"
            src="../images/icon.png"
            formats={['auto', 'webp', 'avif']}
          />
        ) : isDark ? (
          <StaticImage
            width={160}
            quality={100}
            alt="Jamestown"
            src="../images/logo-light.png"
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <StaticImage
            width={160}
            quality={100}
            alt="Jamestown"
            src="../images/logo.png"
            formats={['auto', 'webp', 'avif']}
          />
        )}
      </Flex>

      {isSmallScreen && (
        <Fade in={mobileMenu.isOpen}>
          <List
            position="absolute"
            borderColor="#E2E8F0"
            color="purple"
            borderWidth="1px"
            ml="2rem"
            role="menu"
            spacing=".9rem"
            alignItems="left"
            bg="white"
            w="200px"
            maxW="90vw"
            borderRadius="md"
            right="var(--cp)"
            top="75px"
            boxShadow="sm"
            py={5}
            px={6}
          >
            {menu.map((item) =>
              item.children ? (
                <ListItem isLazy as={Menu} key={item.name}>
                  {({ isOpen }: { isOpen: boolean }) => (
                    <ListItemWithDropdown isActive={isOpen} {...item} />
                  )}
                </ListItem>
              ) : (
                <ListItem key={item.name}>
                  <Link as={GatsbyLink} to={item.to || '/'} lineHeight={0}>
                    {item.name}
                  </Link>
                </ListItem>
              ),
            )}
          </List>
        </Fade>
      )}

      <HStack spacing={{ md: '2rem', base: '0.5rem' }}>
        {!isSmallScreen && (
          <HStack
            as={List}
            role="menu"
            spacing="2.5rem"
            alignItems="center"
            color={isDark ? 'white' : 'purple'}
          >
            {menu.map((item) =>
              item.children ? (
                <ListItem isLazy as={Menu} key={item.name}>
                  {({ isOpen }: { isOpen: boolean }) => (
                    <ListItemWithDropdown isActive={isOpen} {...item} />
                  )}
                </ListItem>
              ) : (
                <ListItem key={item.name}>
                  <Link as={GatsbyLink} to={item.to || '/'} lineHeight={0}>
                    {item.name}
                  </Link>
                </ListItem>
              ),
            )}
          </HStack>
        )}

        <Button
          border="1px"
          bg="purple"
          color="white"
          fontSize="14px"
          fontWeight="700"
          variant="outline"
          _hover={{ bg: 'purple' }}
          onClick={handleTradeNowButtonClick}
          borderColor={isDark ? 'white' : 'purple'}
        >
          Trade Now
        </Button>

        {isSmallScreen && (
          <IconButton
            isRound
            opacity={0.8}
            variant="ghost"
            aria-label="Open Menu"
            onClick={mobileMenu.onToggle}
            bg={isDark ? 'rgba(225, 225, 225, .1)' : 'rgba(27, 10, 75, 0.1)'}
            _hover={{
              bg: isDark ? 'rgba(225, 225, 225, .1)' : 'rgba(27, 10, 75, 0.1)',
            }}
            _focus={{
              bg: isDark ? 'rgba(225, 225, 225, .1)' : 'rgba(27, 10, 75, 0.1)',
            }}
            icon={
              mobileMenu.isOpen ? (
                <Icon
                  icon="x"
                  width="32px"
                  color={isDark ? 'white' : '#1B0A4B'}
                />
              ) : (
                <Icon
                  icon="menu"
                  width="32px"
                  color={isDark ? 'white' : '#1B0A4B'}
                />
              )
            }
          />
        )}

        {showCurrencyChanger && (
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  as={IconButton}
                  variant="ghost"
                  bg="transparent"
                  isActive={isOpen}
                  _active={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent' }}
                  rightIcon={<ChevronDownIcon />}
                  color={isDark ? 'white' : 'purple'}
                  icon={
                    <CircleFlag
                      width="15px"
                      countryCode={`${currency[0]}${currency[1]}`}
                      className="currency-flag"
                    />
                  }
                />

                <MenuList
                  sx={{
                    '.currency-flag': {
                      marginRight: '8px',
                    },
                  }}
                >
                  <MenuItem onClick={() => setCurrency('ngn')}>
                    <CircleFlag
                      width="15px"
                      countryCode="ng"
                      className="currency-flag"
                    />
                    NGN
                  </MenuItem>
                  <MenuItem onClick={() => setCurrency('usd')}>
                    <CircleFlag
                      width="15px"
                      countryCode="us"
                      className="currency-flag"
                    />
                    USD
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        )}
      </HStack>
    </Flex>
  );
};

export default Header;
