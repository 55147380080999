import { useBreakpointValue } from '@chakra-ui/react';

const usePadding = (additionOrSubtraction: number = 0): string => {
  const padding = useBreakpointValue<number>({
    '2xl': 15,
    xl: 12,
    lg: 8,
    md: 4,
    base: 1,
  });

  return `${(padding as number) + additionOrSubtraction}rem`;
};

export default usePadding;
