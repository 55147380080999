import React from 'react';
import {
  Grid,
  Text,
  List,
  ListItem,
  Link,
  GridItem,
  HStack,
  Heading,
  Flex,
  Button,
  VStack,
  useBreakpointValue,
  Box,
  chakra,
} from '@chakra-ui/react';
import { SocialIcon } from 'react-social-icons';
import { StaticImage } from 'gatsby-plugin-image';
import { Link as GatsbyLink } from 'gatsby';

interface Menu {
  name: string;
  items: Array<Record<'name' | 'link', string>>;
}

const menu: Array<Menu> = [
  {
    name: 'Services',
    items: [
      { link: '/currencies', name: 'Digital Currencies' },
      { link: '/shop', name: 'Ecommerce' },
      { link: '/shipping', name: 'Logistics' },
    ],
  },
  {
    name: 'Company',
    items: [
      { link: '/about', name: 'About Us' },
      { link: '/legal/privacy-policy', name: 'Privacy Policy' },
      { link: '/legal/terms-and-conditions', name: 'Terms & Conditions' },
      { link: '/contact', name: 'Contact Us' },
    ],
  },
];

const socialMedia: Array<Record<'name' | 'link', string>> = [
  {
    name: 'whatsapp',
    link: 'https://wa.me/+2348145450772',
  },
  {
    name: 'instagram',
    link: 'https://instagram.com/jamestown_ng',
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/jamestown_ng',
  },
  {
    name: 'linkedin',
    link: 'https://linkedin.com/company/jamestown-global-synergy/',
  },
  {
    name: 'facebook',
    link: 'https://facebook.com/Jamestownglobal',
  },
  {
    name: 'youtube',
    link: 'https://youtube.com/channel/UChejxi7ZFq9eq9eCqC4Ev7A',
  },
];

const Footer: React.FC = () => {
  const isSmallScreen = useBreakpointValue({
    lg: false,
    md: false,
    base: true,
  });

  return (
    <Grid
      gap={10}
      bg="purple"
      as="footer"
      rowGap={12}
      color="#fff"
      p="5rem var(--cp)"
      templateColumns={{ md: 'repeat(5, 1fr)', base: 'repeat(2, 1fr)' }}
      templateRows={{ base: '1fr 1fr' }}
    >
      <GridItem
        py={15}
        as={Flex}
        colSpan={{ md: 5, base: 2 }}
        justifyContent="space-between"
        flexDir={{ md: 'row', base: 'column' }}
        alignItems={{ md: 'center', base: 'left' }}
      >
        <VStack align="left" width="500px" maxWidth="100%" spacing={5}>
          <Heading size="xl">
            True love feels like{' '}
            <chakra.span color="aquamarine" fontWeight={900}>
              Jamestown
            </chakra.span>
          </Heading>
          <Text>
            We are the perfect companion, always there with you all the steps of
            the way, making life easy. Our doors are always open, we are waiting
            for you.
          </Text>
        </VStack>

        <Box mt={{ md: 0, base: 10 }}>
          <Link href="/currencies">
            <Button
              size="lg"
              color="purple"
              bg="aquamarine"
              _hover={{ bg: 'aquamarine' }}
            >
              Get Started
            </Button>
          </Link>
        </Box>
      </GridItem>
      {!isSmallScreen && (
        <GridItem colSpan={{ md: 2, base: 2 }} height="300px" as="article">
          <StaticImage
            src="../images/logo-light.png"
            width={200}
            quality={100}
            formats={['auto', 'webp', 'avif']}
            alt="Jamestown"
          />
        </GridItem>
      )}

      {menu.map(({ name, items }) => (
        <GridItem key={name} colSpan={1} as="article">
          <Heading size="xs" fontWeight={500} mb={5} color="#ddd">
            {name}
          </Heading>
          <List>
            {items.map((item) => (
              <ListItem key={item.link} mb={4}>
                <Link as={GatsbyLink} to={item.link} color="#fff">
                  {item.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </GridItem>
      ))}

      <GridItem as="article" colSpan={{ md: 1, base: 2 }}>
        <Text size="sm" fontWeight="normal" mb={3}>
          We are active 24/7 and we are waiting for you. Find us on:
        </Text>
        <HStack
          mb={3}
          sx={{
            '.social-icon': {
              width: '32px !important',

              svg: {
                borderRadius: '10px !important',
              },
            },
          }}
        >
          {socialMedia.map((account) => (
            <SocialIcon
              key={account.name}
              url={account.link}
              bgColor="#00EFC1"
              fgColor="#1B0A4B"
              network={account.name}
            />
          ))}
        </HStack>
        <Text mt={6}>
          &copy; {new Date().getFullYear()} Jamestown. All rights reserved.
        </Text>
      </GridItem>
    </Grid>
  );
};

export default Footer;
