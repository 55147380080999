import { useState, useCallback, ReactEventHandler } from 'react';
import Fuse from 'fuse.js';

const useSearch = <T extends any>(collection: T[], keys: string[]) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<T[]>([]);

  const handleSearch = useCallback(
    (value) => {
      if (!value) {
        return setResults([]);
      }

      const fuse = new Fuse(collection, {
        keys,
        threshold: 0.2,
      });

      const results = fuse.search(value).map(({ item }) => item);
      return setResults(results);
    },
    [collection, keys],
  );

  const handleOnInput: ReactEventHandler = (event) => {
    const { value } = event.target as HTMLInputElement;
    setQuery(value);
    handleSearch(value);
  };

  return {
    query,
    results,
    handleOnInput,
  };
};

export default useSearch;
