import { useQuery } from 'react-query';

const useCoins = () =>
  useQuery(
    'coins',
    async () => {
      const response = await fetch('https://api.coincap.io/v2/assets');
      const { data } = await response.json();
      return data;
    },
    { initialData: [] },
  );

export default useCoins;
